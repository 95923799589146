<template>
  <Layout class="pos-rel">
    <div class="banner"
         v-if="bannerBg"
         :style="`background-image:url(${bannerBg})`">
    </div>
    <div class="trans-box"
         :style="{marginTop:`${translateHeight}px`}">
      <div class="rule-model">
        <h1 class="model-title tx-c">兴趣与专业两者兼得</h1>
        <h3 class="tx-c">从启蒙至演奏级</h3>
        <h3 class="tx-c">十二阶段全级别课程规划</h3>
        <div class="rule-width flex flex-sb">
          <div class="ladder flex flex-c flex-align">
            <img class="font1"
                 src="@/assets/img/pro10.png"
                 alt="">
            <img class="font2"
                 src="@/assets/img/pro11.png"
                 alt="">
            <img src="@/assets/img/pro2.png"
                 :class="ladderNum == 2 ? 'active' :''"
                 alt="">
            <img src="@/assets/img/pro3.png"
                 :class="ladderNum == 3 ? 'active' :''"
                 alt="">
            <img src="@/assets/img/pro4.png"
                 :class="ladderNum == 4 ? 'active' :''"
                 alt="">
            <img src="@/assets/img/pro5.png"
                 :class="ladderNum == 5 ? 'active' :''"
                 alt="">
            <img src="@/assets/img/pro6.png"
                 :class="ladderNum == 6 ? 'active' :''"
                 alt="">
            <img src="@/assets/img/pro7.png"
                 :class="ladderNum == 7 ? 'active' :''"
                 alt="">
            <div class="btn flex flex-sb flex-align">
              <div class="box"
                   @mouseover="ladderNum = 2"
                   style="width:38px;"></div>
              <div class="box"
                   @mouseover="ladderNum = 3"
                   style="width:135px;"></div>
              <div class="box"
                   @mouseover="ladderNum = 4"
                   style="width:130px;"></div>
              <div class="box"
                   @mouseover="ladderNum = 5"
                   style="width:85px;"></div>
              <div class="box"
                   @mouseover="ladderNum = 6"
                   style="width:95px;"></div>
              <div class="box"
                   @mouseover="ladderNum = 7"
                   style="width:45px;"></div>
            </div>
          </div>
          <div class="introduce"
               v-if="ladderNum == 2">
            <h3>0-1级</h3>
            <h4>从基本弹琴姿势入手<br>
              让学生理解正确的乐器演奏基础<br>
              在起点上便快人一步</h4>
            <h4 class="color">小型乐曲20首<br>
              如布谷鸟、扬基歌</h4>
          </div>
          <div class="introduce"
               v-if="ladderNum == 3">
            <h3>1-3级</h3>
            <h4>培养良好的读谱习惯<br>
              加强演奏时的手指独立性<br>
              逐步建立起乐感与审美</h4>
            <h4 class="color">中小型乐曲50首<br>
              如小汉斯、音乐之声</h4>
          </div>
          <div class="introduce"
               v-if="ladderNum == 4">
            <h3>4-6级</h3>
            <h4>熟悉更多复杂读谱技巧<br>
              拓展学生手指的各项演奏能力<br>
              体会音乐的呼吸律动</h4>
            <h4 class="color">中小型乐曲40首<br>
              如加沃特舞曲、土耳其进行曲</h4>
          </div>
          <div class="introduce"
               v-if="ladderNum == 5">
            <h3>7-8级</h3>
            <h4>加入更多演奏法<br>
              多种钢琴踏板的运用<br>
              多层次乐感的表达</h4>
            <h4 class="color">大型乐曲30首<br>
              如贝多芬悲怆奏鸣曲</h4>
          </div>
          <div class="introduce"
               v-if="ladderNum == 6">
            <h3>9-10级</h3>
            <h4>尝试音乐的各种风格<br>
              学习和声的色彩变化<br>
              强调音色的丰富多彩</h4>
            <h4 class="color">超大型乐曲20首<br>
              如肖邦奏鸣曲</h4>
          </div>
          <div class="introduce"
               v-if="ladderNum == 7">
            <h3>演奏级</h3>
            <h4>技巧与音乐<br>
              真正的结合与碰撞</h4>
          </div>
        </div>
      </div>

      <div class="rule-model-bg">
        <h1 class="model-title tx-c">跟老师学or自己学</h1>
        <h3 class="tx-c">二款应用程序应对不同学习场景</h3>
        <h3 class="tx-c">三大学习模式轻松跨越学琴难点</h3>
        <div class="study rule-width">
          <div class="left pos-rel flex flex-c flex-align">
            <img class="pos-abs small"
                 src="@/assets/img/pro13.png"
                 alt="">
            <img src="@/assets/img/pro8.png"
                 alt="">
            <div>
              <h2>1V2在线互动课堂</h2>
              <h4 class="btn tx-c"
                  @click="$router.push({name:'down'})">立即下载</h4>
              <h4>学习模式/竞技模式/技巧模式<br>
                三大学习模式<br>
                开启不一样的学习体验</h4>
              <h5>
                进阶式12阶段课程 培养五大能力<br>
                全面提升音乐素养 应对考级标准 紧扣考点<br>
                突破表演与技术难点 加强综合表演能力
              </h5>
            </div>
          </div>
          <div class="right flex flex-c flex-align pos-rel">
            <img class="pos-abs small"
                 src="@/assets/img/pro12.png"
                 alt="">

            <div>
              <h2>AI互动课堂</h2>
              <h4 class="btn tx-c"
                  @click="$router.push({name:'down'})">立即下载</h4>
              <h4>剧情式互动 孩子更感兴趣<br>
                IP陪伴模式 孩子更易坚持</h4>
              <h5>
                专业钢琴教学 去玩 去学 去练<br>
                科学循环教学<br>
                在线钢琴学练一体
              </h5>
            </div>
            <img src="@/assets/img/pro9.png"
                 alt="">
          </div>

        </div>
      </div>

    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/index'
import bannerBg from '@/assets/img/pro1.png'
import animated from '@/utils/animated.js'

export default {
  components: {
    Layout,
  },
  data() {
    return {
      bannerBg: bannerBg,
      ladderNum: 2,
    }
  },
  mixins: [animated],
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 1080px;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.trans-box {
  background: #fff;
  transition: 1.6s ease;
}
.ladder {
  width: 810px;
  height: 540px;
  margin-top: 100px;
  position: relative;
  .font1 {
    display: block;
    top: -80px;
    width: 150px;
    left: 100px;
    z-index: 2;
  }
  .font2 {
    display: block;
    top: 0px;
    width: 200px;
    right: -280px;
    z-index: 2;
  }
  img {
    width: 100%;
    position: absolute;
    display: none;
    z-index: 0;
    &.active {
      display: block;
    }
  }
  .btn {
    height: 100%;
    position: absolute;
    z-index: 1;
    margin-left: 20px;
    .box {
      height: 70%;
      cursor: pointer;
    }
  }
}
.introduce {
  margin-top: 360px;
  width: 300px;
  h3 {
    width: 90px;
    height: 40px;
    line-height: 42px;
    margin-bottom: 12px;
    color: #fff;
    text-align: center;
    background: #ffad40;
    border-radius: 12px;
  }
  h4 {
    line-height: 30px;
    color: #333;
    &.color {
      color: #ffad40;
    }
  }
}
.study {
  h2 {
    font-weight: bold;
  }
  h4 {
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 30px;
  }
  h5 {
    font-size: 18px;
    line-height: 30px;
  }
  .btn {
    width: 106px;
    height: 34px;
    line-height: 34px;
    color: #fff;
    background: #ff7940;
    margin: 20px 0 50px;
    cursor: pointer;
  }
  .left {
    img {
      width: 45%;
      margin-right: 65px;
      margin-left: -164px;
    }
    .small {
      width: 194px;
      height: 109px;
      right: 10px;
      top: 86px;
    }
  }
  .right {
    .btn {
      background: #ffad40;
    }
    img {
      width: 62%;
      margin-right: -140px;
      margin-left: 140px;
    }
    .small {
      width: 135px;
      height: 100px;
      left: 182px;
      top: 50px;
    }
  }
}
</style>
